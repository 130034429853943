import * as React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { StaticImage } from "gatsby-plugin-image";

// Import styles
import "./styles/testimonials.scss";

// Bootstrap imports
import { Button, Carousel } from "react-bootstrap";

const Testimonials = (props) => {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      allWpTestimonial {
        nodes {
          id
          testimonialFields {
            quote
          }
        }
      }
    }
  `);

  // Wordpress data
  const { allWpTestimonial } = data;

  return (
    <div id="testimonials" className="testimonials-slider">
      <Carousel touch="true">
        {allWpTestimonial.nodes.map((testimonial) => (
          <Carousel.Item key={testimonial.id}>
            <Carousel.Caption className="fade-in-slow">
              <p className="talk">
                <span>&ldquo;</span>
                {testimonial.testimonialFields.quote}
                <span>&rdquo;</span>
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
};

export default Testimonials;
