import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import PropTypes from "prop-types";
import { StaticImage } from "gatsby-plugin-image";

// Import components
import Navigation from "../components/Navigation";
import Testimonials from "../components/Testimonials";

// Import styles
import "bootstrap/dist/css/bootstrap.min.css";
import "../base.scss";
import "../assets/fonts/fonts.css";
import "../variables.scss";
import "./styles/main.scss";

// Icons
import { FaFacebook, FaGoogle } from "react-icons/fa";

// Bootstrap imports
import { Button, Col, Container, Row } from "react-bootstrap";

const Main = ({ children }) => {
  // Wordpress Query
  const data = useStaticQuery(graphql`
    {
      wpSetting(title: { eq: "Footer" }) {
        footerFields {
          address
          hours {
            monday
            tuesday
            wednesday
            thursday
            friday
            saturday
            sunday
          }
        }
      }
    }
  `);

  // Wordpress data
  const { wpSetting } = data;

  return (
    <>
      <header>
        <Navigation />
      </header>
      <main>{children}</main>
      <footer>
        <div md={5} className="background-shape-1" />
        <div md={5} className="background-shape-2" />
        <Container className="fade-in-slow">
          <Row>
            <Col className="location" xs={12} md={5}>
              <h3 className="yell">Our Location</h3>
              <div
                className="talk post-description"
                dangerouslySetInnerHTML={{
                  __html: wpSetting.footerFields.address,
                }}
              />
              <h3 className="yell">Our Hours</h3>
              <ul>
                <li className="talk">
                  <span>Mon:</span>{" "}
                  <span>{wpSetting.footerFields.hours.monday}</span>
                </li>
                <li className="talk">
                  <span>Tue:</span>{" "}
                  <span>{wpSetting.footerFields.hours.tuesday}</span>
                </li>
                <li className="talk">
                  <span>Wed:</span>{" "}
                  <span>{wpSetting.footerFields.hours.wednesday}</span>
                </li>
                <li className="talk">
                  <span>Thur:</span>{" "}
                  <span>{wpSetting.footerFields.hours.thursday}</span>
                </li>
                <li className="talk">
                  <span>Fri:</span>{" "}
                  <span>{wpSetting.footerFields.hours.friday}</span>
                </li>
                <li className="talk">
                  <span>Sat:</span>{" "}
                  <span>{wpSetting.footerFields.hours.saturday}</span>
                </li>
                <li className="talk">
                  <span>Sun:</span>{" "}
                  <span>{wpSetting.footerFields.hours.sunday}</span>
                </li>
              </ul>
              <div className="facebook-link">
                <a
                  target="_blank"
                  href="https://www.facebook.com/MountaineerDental"
                >
                  <FaFacebook />
                  Follow Us On Facebook
                </a>
              </div>
            </Col>
            <Col className="map" xs={12} md={7}>
              <h3 className="yell">Come See Us</h3>
              <div className="map-container">
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3094.2640124753616!2d-80.03793558456496!3d39.14597637953315!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x884af55327ce9a35%3A0xe9eb45d747caa34e!2sMountaineer%20Dental!5e0!3m2!1sen!2sus!4v1627602995352!5m2!1sen!2sus"
                  loading="lazy"
                ></iframe>
              </div>
            </Col>
            <Col className="testimonials" xs={12}>
              <h3 className="yell">What Are People Saying?</h3>
              <div className="testimonials-container">
                <Testimonials />
              </div>
              <div className="google-link">
                <a
                  target="_blank"
                  href="https://www.google.com/search?q=mountaineer+dental+philippi+wv+google+page&amp;sxsrf=AOaemvLOB
                  DlNL49GGFvNCzS5F4e86P_60A%3A1631121015317&amp;ei=d-
                  44YdvsEuyFwbkPuPmByAs&amp;oq=mountaineer+dental+philippi+wv+google+page&amp;gs_lcp=Cgxnd3Mtd2l6L
                  XNlcnAQAzIFCCEQoAE6EAguEMcBEK8BELADECcQkwI6BwgAEEcQsAM6BggAEBYQHjoHCCEQChCgAToEC
                  CEQCjoFCCEQqwJKBAhBGABQrmhY9HdgpnloAXACeACAAZoBiAHoC5IBBDAuMTKYAQCgAQHIAQTAAQE&amp;
                  sclient=gws-wiz-serp&amp;ved=0ahUKEwibhNCl7-
                  _yAhXsQjABHbh8ALkQ4dUDCA8&amp;uact=5#lrd=0x884af55327ce9a35:0xe9eb45d747caa34e,1,,,"
                >
                  <FaGoogle />
                  Leave Us A Review
                </a>
              </div>
            </Col>
          </Row>
        </Container>
        <div className="copyright">
          <Container className="fade-in-slow">
            <AnchorLink
              to="#top"
              title="Back to top"
              className="stripped"
              stripHash
            >
              <Button variant="accent">Back To Top </Button>
            </AnchorLink>
            <h6 className="talk">
              © {new Date().getFullYear()} Mountaineer Dental
              <br /> All Rights Reserved
            </h6>
          </Container>
        </div>
      </footer>
    </>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
