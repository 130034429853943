import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Import styles
import "./styles/navigation.scss";

// Import bootstrap
import { Button, Container, Navbar } from "react-bootstrap";

const Navigation = () => {
  const [menuState, setMenuState] = useState("closed");

  function menuToggler() {
    setMenuState(menuState === "closed" ? "open" : "closed");
  }

  let menu = (
    <div className={`menu ${menuState}`}>
      <ul className="menu-container">
        <li>
          <Link to="/" activeClassName="nav-active" onClick={menuToggler}>
            Home
          </Link>
        </li>
        <li>
          <Link
            to="/staff"
            activeClassName="nav-active"
            partiallyActive={true}
            onClick={menuToggler}
          >
            Staff
          </Link>
        </li>
        <li>
          <Link
            to="/services"
            activeClassName="nav-active"
            partiallyActive={true}
            onClick={menuToggler}
          >
            Services
          </Link>
        </li>
        <li>
          <Link
            to="/patient-information"
            activeClassName="nav-active"
            onClick={menuToggler}
          >
            Patient Information
          </Link>
        </li>
        <li>
          <Link
            to="/contact-us"
            activeClassName="nav-active"
            onClick={menuToggler}
          >
            Contact Us
          </Link>
        </li>
        <li>
          <a href="tel:304-457-0590" onClick={menuToggler}>
            <Button variant="secondary">+1-304-457-0590</Button>
          </a>
        </li>
      </ul>
    </div>
  );

  return (
    <>
      <Navbar id="top" expand="lg" className={menuState}>
        <Container className="navbar-container">
          <div className="navbar-brand">
            <Link to="/">
              <StaticImage
                className="image"
                src="../assets/images/nav-logo.png"
                alt="The Adaptix logo."
                placeholder="tracedSVG"
              />
            </Link>
          </div>
          <div className="navbar-hamburger">
            <button
              className={menuState}
              onClick={menuToggler}
              aria-label="Main Menu"
            >
              <svg width="40" height="40" viewBox="0 0 100 100">
                <path
                  className="line line1"
                  d="M 20,29.000046 H 80.000231 C 80.000231,29.000046 94.498839,28.817352 94.532987,66.711331 94.543142,77.980673 90.966081,81.670246 85.259173,81.668997 79.552261,81.667751 75.000211,74.999942 75.000211,74.999942 L 25.000021,25.000058"
                />
                <path className="line line2" d="M 20,50 H 80" />
                <path
                  className="line line3"
                  d="M 20,70.999954 H 80.000231 C 80.000231,70.999954 94.498839,71.182648 94.532987,33.288669 94.543142,22.019327 90.966081,18.329754 85.259173,18.331003 79.552261,18.332249 75.000211,25.000058 75.000211,25.000058 L 25.000021,74.999942"
                />
              </svg>
            </button>
          </div>
          <div className="desktop-only">{menu}</div>
        </Container>
      </Navbar>
      <div className="mobile-only">{menu}</div>
    </>
  );
};

export default Navigation;
